<template>
    <div :class="['modal', { 'is-opened': isOpen }]" id="modal" v-if="isOpen">
        <div class="modal-overlay modal-trigger"></div>
        <div class="modal-window" v-click-outside="close">
            <div class="modal-header">
                <slot name="header">
                    <h3 class="modal-title">{{ title }}</h3>
                    <button type="button" class="modal-close modal-trigger" @click="close">×</button>
                </slot>
            </div>
            <div class="modal-body">
                <slot name="body"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isOpen: { type: Boolean, default: false },
        title: { type: String, default: "" },
    },
    methods: {
        close() {
            this.$emit("update:isOpen", false)
        },
        open() {
            this.$emit("update:isOpen", true)
        },
        toggle() {
            this.$emit("update:isOpen", !this.isOpen)
        },
    },
}
</script>
